<template>
  <div v-if="small === false" class="col-lg-8 col-xl-9 col-xxl-8 mx-auto d-flex justify-content-center flex-column">
    <div class="card d-flex justify-content-center py-3 px-1 shadow-lg">
      <div class="text-center">
        <p class="lead mt-3">{{ $t('slogan') }}</p>
        <p class="mb-0">{{ $t('enterVinFindMore') }}</p>
      </div>
      <div class="card card-plain">
        <form @submit.prevent="handleSubmit">
          <div class="card-body pb-2">
            <div class="row">
              <div class="col-md-12">
                <div class="input-group input-group-static mb-4">
                  <input v-model="dvin" aria-label="VIN" class="form-control input-vin" maxlength="17" name="vin" :placeholder="t('eg', { value: 'YV1CZ90G6E1185894'})" type="text">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <button class="btn bg-gradient-primary mt-3 mb-0" type="submit">{{ $t('findOutMore') }}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="small">
    <form @submit.prevent="handleSubmit">
      <el-input :placeholder="t('eg', { value: 'YV1CZ90G6E1185894'})" v-model="dvin" maxlength="17" clearable>
        <template #append>
          <el-button class="fa fa-search" @click.prevent="handleSubmit"></el-button>
        </template>
      </el-input>
    </form>
  </div>
</template>

<script>
import BaseComponent from "@/components/BaseComponent";
import helpers from "@/helpers";
import {i18n} from "@/main";

export default {
  extends: BaseComponent,
  name: 'VinSearch',
  emits: [ 'vin-changed' ],
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    vin: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      dvin: ''
    }
  },
  methods: {
    handleSubmit() {
      if (helpers.validate.vin(this.dvin) === false) {
        this.$alert(this.t('thisIsNotValidVin', {vin: this.dvin}));
        return;
      }

      this.$router.push({ name: 'Vin', params: { vin: this.dvin } });
      this.$emit('vin-changed', this.dvin);
    },
    t(key, args = null) {
      return i18n.global.t(key, args);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.vin !== null) {
        this.dvin = this.vin.toString();
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.input-vin {
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;

  &::-webkit-input-placeholder {
    text-transform: none;
  }

  &:-moz-placeholder {
    text-transform: none;
  }

  &::-moz-placeholder {
    text-transform: none;
  }

  &:-ms-input-placeholder {
    text-transform: none;
  }

  &::placeholder {
    text-transform: none;
  }
}
</style>