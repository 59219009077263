// noinspection JSUnresolvedVariable

import "numeral";
import CryptoJS from "crypto-js";
import {publicIpv4} from 'public-ip';

const funcs = {
	date: {
		phpDateToFormat(phpDate, format) {
			return moment.utc(phpDate.date).format(format);
		},
		jsonDateToFormat(jsonDate, format) {
			return moment(jsonDate).format(format);
		},
		getActualDay: function () {
			let actualDay = (new Date()).getDay();
			if (actualDay === 0) {
				actualDay = 7;
			}

			return actualDay;
		},
		getActualHour: function () {
			return (new Date()).getHours();
		},
		getActualMinute: function () {
			return (new Date()).getMinutes();
		}
	},
	string: {
		capitalizeFirstLetter(str) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
		countWords(str) {
			return str.split(' ')
				.filter(function(n) { return n !== '' })
				.length;
		},
		countUniqueChars(str, isCaseSensitive = false, ignoreWhitespace = true) {
			let uniq = '';

			if (isCaseSensitive === false) {
				str = str.toLowerCase();
			}

			if (ignoreWhitespace === true) {
				str = str.replace(' ', '');
			}

			for (let x = 0; x < str.length; x++)
			{
				if(uniq.indexOf(str.charAt(x)) === -1)
				{
					uniq += str[x];
				}
			}

			return uniq.length;
		}
	},
	file: {
		humanFileSize(bytes, si = true, dp = 1) {
			const thresh = si ? 1000 : 1024;

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}

			const units = si
				? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			const r = 10 ** dp;

			do {
				bytes /= thresh;
				++u;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


			return bytes.toFixed(dp) + ' ' + units[u];
		},
		isFileImage(file) {
			return file && file['type'].split('/')[0] === 'image';
		},
		isFileVideo(file) {
			return file && file['type'].split('/')[0] === 'video';
		},
		isFileAudio(file) {
			return file && file['type'].split('/')[0] === 'audio';
		}
	},
	number: {
		toFixedNumber(num, digits, base = 10) {
			let pow = Math.pow(base, digits);
			return Math.round(num * pow) / pow;
		},
		random(min, max) {
			return Math.floor(Math.random() * ((max) - min)) + min;
		}
	},
	axios: {
		config(headers = {}, onUploadProgress = () => {}) {
			return {
				transformResponse: [
					(data, headers) => this.proccessData(data, headers)
				],
				//headers: {...{"X-Requested-With": "XMLHttpRequest"}, ...headers},
				headers: {...{"Content-Type" : 'application/x-www-form-urlencoded; charset=UTF-8'}, ...headers},
				onUploadProgress: onUploadProgress
			};
		},
		proccessData(data, headers) {
			if (data === undefined && headers === undefined) {
				return 0;
			}
			let contentType = headers['content-type'];
			if (contentType !== undefined && contentType.includes('application/encrypt')) {
				let iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
				let key256Bits = CryptoJS.enc.Utf8.parse('8/T*W;7L/T~el~Ev57+1Tw?DNyR!o^dn');
				data = CryptoJS.AES.decrypt(data, key256Bits, {iv: iv}).toString(CryptoJS.enc.Utf8);
			}

			let ret = data;

			if (contentType.includes('application/encrypt') || contentType.includes('application/json')) {
				ret = JSON.parse(data);
			}

			if ('redirect' in ret) {
				window.location = ret.redirect;
			}
			return ret;
		},
		data(objParams) {
			return new URLSearchParams(objParams).toString()
		}
	},
	filters: {
		firstUpper(value) {
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
		lower(value) {
			return value.toLowerCase();
		},
		truncate(value, length, append = '...') {
			if (value.length <= length) {
				return value;
			}
			return value.slice(0, length) + append
		},
		distancify(value, lang) {
			let rkm = Number.parseFloat(value).toFixed(2);
			let rmi = (rkm / 1.609).toFixed(2);
			return `${rkm} ${lang.info.km} (${rmi} ${lang.info.mi})`;
		},
		distance(valueInKm, lang, unit, precision = 0) {
			let value = Number.parseFloat(valueInKm).toFixed(precision);
			switch (unit) {
				case 'mi':
					let mil = value / 1.609344;
					return `${numeral(mil).format('0,0.0')} ${lang.info.mi}`;
				case 'km':
				default:
					return `${numeral(value).format('0,0')} ${lang.info.km}`;
			}
		},
		temperature(value, lang, unit) {
			let celsius = Number.parseFloat(value);
			switch (unit) {
				case 'celsius':
					return `${numeral(celsius).format('0,0')}°C`;
				case 'fahrenheit':
				default:
					let fahrenheit = ((celsius * 1.8) + 32);
					return `${numeral(fahrenheit).format('0,0.0')}°F`;
			}
		},
		fluid(value, lang) {
			let val = Number.parseFloat(value);
			return `${numeral(val).format('0,0.0')} ${lang.info.uLiter}`;
		},
		numberWithCommas(value, comma = ',') {
			let processed = value.toString().replace(/ /g, '');
			let number = parseFloat(processed);
			if (isNaN(number)) {
				number = '';
			}
			return number.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, comma);
		},
		dealer: {
			country(dealer, lang) {
				return lang.countries[dealer.countryCode.toLowerCase()];
			},
			iconColor(dealer) {
				switch (dealer.type) {
					case 'Small': return 'text-warning';
					case 'Pro': return 'text-success';
					case 'ProVerified': return 'text-danger';
					default: return 'text-secondary';
				}
			}
		}
	},
	responsive: {
		is(str) {
			return responsive.is(str);
		}
	},
	browser: {
		async ipAddress() {
			try {
				return await publicIpv4();
			} catch (e) {
				console.log(e);
			}
			return null;
		},
		reload() {
			window.location.reload();
		},
		urlBase64ToUint8Array(base64String) {
			const padding = "=".repeat((4 - base64String.length % 4) % 4);
			// noinspection RegExpRedundantEscape
			const base64 = (base64String + padding)
				.replace(/\-/g, "+")
				.replace(/_/g, "/");

			const rawData = window.atob(base64);
			const outputArray = new Uint8Array(rawData.length);

			for (let i = 0; i < rawData.length; ++i) {
				outputArray[i] = rawData.charCodeAt(i);
			}
			return outputArray;
		},
		timeout(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		findAndReplace(searchText, replacement, searchNode) {
			if (!searchText || typeof replacement === 'undefined') {
				// Throw error here if you want...
				return;
			}

			let regex = new RegExp(searchText);
				let childNodes = (searchNode || document.body).childNodes,
				cnLength = childNodes.length,
				excludes = 'html,head,style,title,link,meta,script,object,iframe';

			while (cnLength--) {
				let currentNode = childNodes[cnLength];
				if (currentNode.nodeType === 1 &&
					(excludes + ',').indexOf(currentNode.nodeName.toLowerCase() + ',') === -1) {
					funcs.browser.findAndReplace(searchText, replacement, currentNode);
				}

				if (currentNode.nodeType !== 3 || !regex.test(currentNode.data) ) {
					continue;
				}


				let parent = currentNode.parentNode,
					frag = (function(){
						let wrap = document.createElement('div'),
							frag = document.createDocumentFragment();
						wrap.innerHTML = currentNode.data.replace(regex, replacement);
						while (wrap.firstChild) {
							frag.appendChild(wrap.firstChild);
						}
						return frag;
					})();
				parent.insertBefore(frag, currentNode);
				parent.removeChild(currentNode);
			}
		}
	},
	validate: {
		email(value) {
			const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			return re.test(String(value).toLowerCase());
		},
		vin(value) {
			if (value.length !== 17) {
				return false;
			}

			let vinPrefixes = ['YV1', 'YV4', 'PNV', 'LYV', 'LVY', 'SAL', 'SFA', 'VSK', 'VS6', 'TW2', 'UN1', 'XLC', '9BF', 'Y4F', 'YV2', 'LVS', '7JR', 'YSM', 'LPS'];

			let found = false;
			vinPrefixes.every(vinPrefix => {
				if (value.startsWith(vinPrefix)) {
					found = true;
					return false; //every-break
				}
				return true; //every-continue
			});

			return found;
		}
	},
	bits: {
		toLangArray(value, langGroup, langPrefix, count = 16) {
			let ret = [];
			let lastBit = 1;
			for (let i = 1; i <= count; i++) {
				let key = langPrefix + lastBit;
				if (value & lastBit && langGroup[key]) {
					ret.push(langGroup[key]);
				}
				lastBit += lastBit;
			}
			return ret;
		}
	},
	array: {
		search(columns, query, item) {
				query = query.toLowerCase();
				let res = false;

				for (let column of columns) {
					let value = item[column];
					if (res === true || this.isEmpty(value)) {
						continue;
					}

					value = value.toLowerCase();
					if (value.includes(query)) {
						res = true;
						break;
					}
				}

				return res;
		}
	},
	localStorage: {
		setWithExpiry(key, value, ttlms) {
			let now = new Date();

			let item = {
				value: value,
				expiry: now.getTime() + ttlms,
			};
			localStorage.setItem(key, JSON.stringify(item))
		},
		getWithExpiry(key, retDefault = null) {
			let itemStr = localStorage.getItem(key);
			if (!itemStr) {
				return retDefault;
			}
			let item = JSON.parse(itemStr);

			let now = new Date();
			if (now.getTime() > item.expiry) {
				localStorage.removeItem(key);
				return retDefault;
			}
			return item.value;
		}
	},
	init: {
		inputs(arr) {
			for (let i = 0; i < arr.length; i++) {
				arr[i].addEventListener('focus', function () {
					this.parentElement.classList.add('is-focused');
				}, false);

				arr[i].onkeyup = function () {
					if (this.value !== "") {
						this.parentElement.classList.add('is-filled');
					} else {
						this.parentElement.classList.remove('is-filled');
					}
				};

				arr[i].addEventListener('focusout', function () {
					if (this.value !== "") {
						this.parentElement.classList.add('is-filled');
					}
					this.parentElement.classList.remove('is-focused');
				}, false);
			}
		},
		form() {
			let textareas = document.querySelectorAll('textarea');
			this.inputs(textareas);

			let inputs = document.querySelectorAll('input');
			this.inputs(inputs);

			let ripples = document.querySelectorAll('.btn');

			for (let i = 0; i < ripples.length; i++) {
				ripples[i].addEventListener('click', function(e) {
					let targetEl = e.target;
					let rippleDiv = targetEl.querySelector('.ripple');

					rippleDiv = document.createElement('span');
					rippleDiv.classList.add('ripple');
					rippleDiv.style.width = rippleDiv.style.height = Math.max(targetEl.offsetWidth, targetEl.offsetHeight) + 'px';
					targetEl.appendChild(rippleDiv);

					rippleDiv.style.left = (e.offsetX - rippleDiv.offsetWidth / 2) + 'px';
					rippleDiv.style.top = (e.offsetY - rippleDiv.offsetHeight / 2) + 'px';
					rippleDiv.classList.add('ripple');
					setTimeout(function() {
						rippleDiv.parentElement.removeChild(rippleDiv);
					}, 600);
				}, false);
			}
		}
	},
	deinit: {
		inputs(arr) {
			for (let i = 0; i < arr.length; i++) {
				// console.log(arr[i].parentElement);
				arr[i].parentElement.classList.remove('is-focused');
				arr[i].parentElement.classList.remove('is-filled');
			}
		},
		form() {
			let textareas = document.querySelectorAll('textarea');
			this.inputs(textareas);

			let inputs = document.querySelectorAll('input');
			this.inputs(inputs);
		}
	}
};

export default funcs;