<template>
  <header>
    <div :style="`background-image: url(` + require('@/assets/img/cardiag-building3.jpg') +`)`" class="page-header min-vh-55">
      <span class="mask bg-info opacity-3"></span>
      <div class="container">
        <div class="row">
          <vin-search></vin-search>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import VinSearch from "@/components/VinSearch";
export default {
  components: {VinSearch}
}
</script>

<style lang="scss" scoped>
.bg-image {
  background-image: url('@/assets/img/cardiag-building3.jpg');
}
</style>