import en from './en.json'
import cs from './cs.json'
import getBrowserLocale from "../lang/get-browser-locale";
import {i18n} from "../main";

const storageKey = 'language';

export function setLocale(locale) {
	localStorage.setItem(storageKey, locale);
	i18n.global.locale = locale;
}

export function isKnownLocale(locale) {
	return i18n.global.availableLocales.includes(locale) === true;
}

export function getLocale(defaultLocale = 'en') {
	let storaged = localStorage.getItem(storageKey);
	if (storaged && isKnownLocale(storaged)) {
		return storaged;
	}

	let locale = getBrowserLocale({ countryCodeOnly: true });
	if (locale === undefined) {
		return defaultLocale;
	}

	if (isKnownLocale(locale)) {
		return locale;
	}

	return defaultLocale;
}

export function localeFlagIso(locale) {
	let localeArr = {
		'cs': 'cz',
		'en': 'gb',
	};

	if (locale in localeArr) {
		return localeArr[locale];
	}

	return localeArr.en;
}

export default {
	en, cs
}
