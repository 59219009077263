<template>
  <nav class="navbar navbar-expand-lg bg-white py-3 position-sticky z-index-sticky top-0 blur">
    <div class="container">
      <router-link @click.native="close" to="/">
        <img :src="require('@/assets/img/logo-172x31.png')" class="img-logo" alt="Logo">
      </router-link>
      <router-link @click.native="close" class="navbar-brand ms-3 text-md" to="/">
        {{ $t('companyName') }}

        <div v-if="isDev" style="display: inline-block;">
          <span class="d-block d-sm-none">XS</span>
          <span class="d-none d-sm-block d-md-none">SM</span>
          <span class="d-none d-md-block d-lg-none">MD</span>
          <span class="d-none d-lg-block d-xl-none">LG</span>
          <span class="d-none d-xl-block d-xxl-none">XL</span>
          <span class="d-none d-xxl-block">XXL</span>
        </div>
      </router-link>
      <button ref="btnNavigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler shadow-none ms-2" data-bs-target="#navigation" data-bs-toggle="collapse" type="button">
        <span class="navbar-toggler-icon mt-2">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div ref="elNavigation" id="navigation" class="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0 ms-lg-6 ms-xl-12 ps-lg-5">
        <ul class="navbar-nav navbar-nav-hover ms-auto">
          <router-link @click.native="close" tag="li" class="nav-item dropdown dropdown-hover mx-2" to="/">
            <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-baseline text-md">
              <em class="far fa-home opacity-6 me-2 text-lg"></em>
              {{ $t('home') }}
              <em class="far fa-fw fa-circle arrow ms-auto ms-md-2 d-lg-none"></em>
            </a>
          </router-link>
          <li class="nav-item dropdown mx-2" :class="{ 'dropdown-hover': enableHover }">
            <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-baseline text-md" data-bs-toggle="dropdown">
              <em class="far fa-screwdriver-wrench opacity-6 me-2 text-lg"></em>
              {{ $t('services') }}
              <em class="far fa-fw fa-chevron-down arrow ms-auto ms-md-2"></em>
            </a>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-3">
              <ul class="d-none d-lg-block list-group">
                <li class="nav-item list-group-item border-0 p-0">
                  <router-link @click.native="close" tag="a" :to="{ name: 'ElectricalDiagnostics' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-md">{{ $t('diagnosticsElectricalInstallation') }}</span>
                  </router-link>
                </li>
                <li class="nav-item list-group-item border-0 p-0">
                  <router-link @click.native="close" tag="a" :to="{ name: 'DiagnosticMechanicalFaults' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-md">{{ $t('diagnosticMechanicalFaults') }}</span>
                  </router-link>
                </li>
                <li class="nav-item list-group-item border-0 p-0">
                  <router-link @click.native="close" tag="a" :to="{ name: 'AutomaticGearboxDecarbonisation' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-md">{{ $t('automaticGearboxDecarbonisation') }}</span>
                  </router-link>
                </li>
                <li class="nav-item list-group-item border-0 p-0">
                  <router-link @click.native="close" tag="a" :to="{ name: 'OptimizingEnginePerformance' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-md">{{ $t('optimizingEnginePerformance') }}</span>
                  </router-link>
                </li>
                <li class="nav-item list-group-item border-0 p-0">
                  <router-link @click.native="close" tag="a" :to="{ name: 'DiagnosticTrainingCourses' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-md">{{ $t('diagnosticTrainingCourses') }}</span>
                  </router-link>
                </li>
                <li class="nav-item list-group-item border-0 p-0">
                  <router-link @click.native="close" tag="a" :to="{ name: 'ClassroomCarRental' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-md">{{ $t('classroomCarRental') }}</span>
                  </router-link>
                </li>
              </ul>
              <div class="row d-lg-none">
                <div class="col-md-12 g-0">
                  <router-link @click.native="close" tag="a" :to="{ name: 'ElectricalDiagnostics' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-sm">{{ $t('diagnosticsElectricalInstallation') }}</span>
                  </router-link>
                  <router-link @click.native="close" tag="a" :to="{ name: 'DiagnosticMechanicalFaults' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-sm">{{ $t('diagnosticMechanicalFaults') }}</span>
                  </router-link>
                  <router-link @click.native="close" tag="a" :to="{ name: 'AutomaticGearboxDecarbonisation' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-sm">{{ $t('automaticGearboxDecarbonisation') }}</span>
                  </router-link>
                  <router-link @click.native="close" tag="a" :to="{ name: 'OptimizingEnginePerformance' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-sm">{{ $t('optimizingEnginePerformance') }}</span>
                  </router-link>
                  <router-link @click.native="close" tag="a" :to="{ name: 'DiagnosticTrainingCourses' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-sm">{{ $t('diagnosticTrainingCourses') }}</span>
                  </router-link>
                  <router-link @click.native="close" tag="a" :to="{ name: 'ClassroomCarRental' }" class="dropdown-item py-2 ps-3 border-radius-md">
                    <span class="text-sm">{{ $t('classroomCarRental') }}</span>
                  </router-link>
                </div>
              </div>
            </ul>
          </li>
          <router-link @click.native="close" tag="li" to="/contact" class="nav-item dropdown dropdown-hover mx-2">
            <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-baseline text-md">
              <em class="far fa-address-book opacity-6 me-2 text-lg"></em>
              {{ $t('contact') }}
              <em class="far fa-fw fa-circle arrow ms-auto ms-md-2 d-lg-none"></em>
            </a>
          </router-link>
          <li class="nav-item my-auto ms-3 ms-lg-0">
            <router-link :to="{ name: 'IWantService' }" class="btn btn-sm bg-gradient-info mb-0 me-1 mt-2 mt-md-0">
              {{ $t('iWantService') }}
            </router-link>
          </li>
          <menu-language-changer></menu-language-changer>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import MenuLanguageChanger from "./MenuLanguageChanger";
import BaseComponent from "@/components/BaseComponent";
import {mittInstance} from "@/main";

export default {
  extends: BaseComponent,
  components: {MenuLanguageChanger},
  data() {
    return {
      enableHover: true
    }
  },
  methods: {
    toggleHover() {
      this.enableHover = !this.enableHover;
      setTimeout(() => {
        this.enableHover = !this.enableHover;
      }, 500);
    },
    close() {
      if (this.$refs.btnNavigation && this.$refs.btnNavigation.attributes) {
        let aria = this.$refs.btnNavigation.attributes.getNamedItem('aria-expanded');
        aria.value = 'false';
        this.$refs.btnNavigation.attributes.setNamedItem(aria);
        this.$refs.btnNavigation.classList.remove('collapsed');
      }

      if (this.$refs.elNavigation) {
        this.$refs.elNavigation.classList.remove('show');
      }

      this.toggleHover();
    }
  },
  mounted() {
    mittInstance().on('closeNavbar', () => this.close());
  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
@import "@/assets/scss/material-kit.scss";

.img-logo {
  max-height: 55px;
}

.navbar-nav .router-link-active {
  background-color: $gray-200;
  border: 1px solid $gray-400;
  border-radius: 0.5rem;
}

.dropdown-menu-end:before {
  color: #7b8090 !important;
}

.dropdown-menu:before {
  font-family: "Font Awesome 6 Pro" !important;
  content: "\f077" !important;
  font-size: 1rem !important;
  top: -15px !important;
}

.align-items-baseline {
  align-items: baseline !important;
}
</style>
