<template>
  <Header></Header>
  <div class="card card-body shadow-blur mx-3 mx-md-4 mt-n4 mb-4">
    <section class="my-4">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12 order-1 order-md-0">
            <h3>{{ $t('ourSpecialization') }}</h3>
            <p class="pe-md-5 mb-4" v-html="t('articleHome[0]')"/>
          </div>
          <div class="col-md-6 col-12 order-0 order-md-1">
            <h3>{{ $t('basicPriceServiceWork') }}</h3>
            <p class="pe-md-5 mb-4" v-html="t('articleHome[8]')"/>
            <h5>{{ $t('automaticTransmissionDecarbonisationFlushingOilChange') }}</h5>
            <p class="pe-md-5 mb-4" v-html="t('articleHome[9]')"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-end text-xs">{{ $t('allPricesWithVat') }}</div>
        </div>
      </div>
    </section>
  </div>

  <section class="py-6 bg-gray-100">
    <div class="container">
      <div class="row">
        <price-cards></price-cards>
      </div>
      <div class="row">
        <div class="col-12">{{ $t('additionalPriceForServiceWork') }}</div>
      </div>
    </div>
  </section>


</template>

<script>
import Header from "@/components/page/header";
import BaseArticle from "@/views/BaseArticle";
import PriceCards from "@/components/PriceCards";
import {event} from "vue-gtag";

export default {
  extends: BaseArticle,
  name: 'Home',
  components: {
    PriceCards,
    Header
  },
  mounted() {
    event('conversion', { 'send_to': 'AW-971213083/jUQJCKTTseMBEJuSjs8D' });
  }
}
</script>

<style>
.table-home tr:hover {
  background: #e5e9f2;
}
</style>
