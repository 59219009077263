<template>
  <Navbar></Navbar>
  <router-view/>

  <Footer></Footer>
</template>

<script>
import Navbar from "@/components/page/navbar";
import Footer from "@/components/page/footer";

export default {
  components: {Footer, Navbar},
  mounted() {

  }
}
</script>
