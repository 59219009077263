<template>
  <li :class="{ 'dropdown-hover': enableHover }" class="nav-item dropdown mx-2 mx-lg-0 d-lg-flex">
    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center text-md" data-bs-toggle="dropdown">
      <flag :iso="localeFlagIso" :squared="false" class="opacity-7 text-lg me-2 me-lg-0"/>
      <span class="d-lg-none">{{ $t('language') }}</span>
      <em class="far fa-fw fa-chevron-down arrow ms-auto ms-md-2"></em>
    </a>
    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-3">
      <ul class="d-none d-lg-block list-group">
        <li class="nav-item list-group-item border-0 p-0">
          <span class="dropdown-item py-2 ps-3 border-radius-md" @click="changeLanguage('cs')">
            <span class="text-md">
              <flag :squared="false" iso="cz" title="Czech"/> {{ $t('czech') }}
            </span>
          </span>
        </li>
        <li class="nav-item list-group-item border-0 p-0">
          <span class="dropdown-item py-2 ps-3 border-radius-md" @click="changeLanguage('en')">
            <span class="text-md">
              <flag :squared="false" iso="gb" title="English"/> {{ $t('english') }}
            </span>
          </span>
        </li>
      </ul>
      <div class="row d-lg-none">
        <div class="col-md-12 g-0">
          <span class="dropdown-item py-2 ps-3 border-radius-md" @click="changeLanguage('cs')">
            <span class="text-sm">{{ $t('czech') }}</span>
          </span>
          <span class="dropdown-item py-2 ps-3 border-radius-md" @click="changeLanguage('en')">
            <span class="text-sm">{{ $t('english') }}</span>
          </span>
        </div>
      </div>
    </ul>
  </li>
</template>

<script>
import {localeFlagIso, isKnownLocale, setLocale} from "../../lang";
import { mittInstance } from "@/main";

export default {
  name: "MenuLanguageChanger",
  data() {
    return {
      enableHover: true
    }
  },
  methods: {
    toggleHover() {
      this.enableHover = !this.enableHover;
      setTimeout(() => {
        this.enableHover = !this.enableHover;
      }, 500);

      mittInstance().emit('closeNavbar');
    },
    changeLanguage(language) {
      if (isKnownLocale(language) === false) {
        this.$alert($t('unknownLanguage'));
      } else {
        setLocale(language);
        mittInstance().emit('languageChanged');
      }
      this.toggleHover();
    }
  },
  computed: {
    localeFlagIso() {
      return localeFlagIso(this.$i18n.locale);
    }
  }
}
</script>

<style scoped>
.dropdown-menu-end:before {
  color: #7b8090 !important;
}

.dropdown-menu:before {
  font-family: "Font Awesome 6 Pro" !important;
  content: "\f077" !important;
  font-size: 1rem !important;
  top: -15px !important;
}

</style>