<template>
	<div v-for="service in services" class="col-xl-6 col-xxl-4 pb-6">
		<div class="card">
			<div class="card-header p-0 m-3 mt-n4 position-relative z-index-2">
				<div class="d-block blur-shadow-image engine-images">
					<a v-if="service.images.length === 1" :href="service.images[0].original" :rel="service.guid"><img :src="service.images[0].thumb" :title="service.preTitle + ' | ' + service.title" alt="img-blur-shadow" class="img-fluid border-radius-lg engine-img"></a>

					<div v-if="service.images.length > 1" :id="service.guid + 'carouselImages'" class="carousel slide" data-bs-ride="carousel">
						<div class="carousel-inner">
							<div v-for="(image, idx) in service.images" :class="{ 'active': idx === 0 }" class="carousel-item">
								<a :href="image.original" :rel="service.guid"><img :src="image.thumb" :title="service.preTitle + ' | ' + service.title" alt="img-blur-shadow" class="d-block w-100 img-fluid border-radius-lg engine-img"></a>
							</div>
						</div>
						<button :data-bs-target="'#' + service.guid + 'carouselImages'" class="carousel-control-prev" data-bs-slide="prev" type="button">
							<span aria-hidden="true" class="carousel-control-prev-icon"></span>
							<span class="visually-hidden">{{ $t('previous') }}</span>
						</button>
						<button :data-bs-target="'#' + service.guid + 'carouselImages'" class="carousel-control-next" data-bs-slide="next" type="button">
							<span aria-hidden="true" class="carousel-control-next-icon"></span>
							<span class="visually-hidden">{{ $t('next') }}</span>
						</button>
					</div>

				</div>
			</div>
			<div class="card-body pt-2">
				<span class="h5 d-block text-dark">
							{{ service.title }}
				</span>
				<div class="service-title">
					<span class="text-gradient text-warning text-uppercase text-xs font-weight-bold my-2">{{ service.preTitle }}</span>
					<el-popover
							:width="300"
							placement="top-start"
							trigger="hover">
						<template #reference>
							<el-switch
									v-show="service.disabledSwitch === false"
									v-model="service.mySwitchValue"
									:active-text="t('from') + ' MY2015'"
									:inactive-text="t('to') + ' MY2014'"
									active-color="#a2d986"
									inactive-color="#409eff">
							</el-switch>
						</template>
						<template #default>
							<span>{{ $t('myDesc2') }} <br> {{ $t('myDesc') }}</span>
						</template>
					</el-popover>

				</div>
				<div class="card-description mb-4">
					<div v-for="item in service.items" :class="item.class" class="row justify-content-evenly">
						<div v-if="item.popover !== undefined" class="col-8 fw-bold">
							<el-popover
									:title="item.popover.title"
									:width="200"
									placement="top-start"
									trigger="hover">
								<template #reference>
									<div v-html="item.left"/>
								</template>
								<template #default>
									<span v-html="item.popover.content"></span>
								</template>
							</el-popover>
						</div>
						<div v-else :class="item.leftClass" v-html="item.left"/>
						<div :class="item.rightClass">
							<el-link :type="service.mySwitchValue ? 'success' : 'primary'" @click="handleSelectedService(service, true)"><span v-html="service.mySwitchValue ? item.right2 : item.right1"/></el-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


	<el-dialog
			v-if="selectedService !== null"
			v-model="isVisibleDialogPriceDetail"
			:title="selectedService.title"
			class="service-dialog"
			draggable
			width="100%"
	>
		<price-detail :service="selectedService"/>
		<template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="isVisibleDialogPriceDetail = false">
          {{ $t('close') }}
        </el-button>
      </span>
		</template>
	</el-dialog>

</template>

<script>
import currencyFormatter from "currency-formatter";
import {mittInstance} from "@/main";
import SimpleLightbox from "simplelightbox";
import BaseComponent from "@/components/BaseComponent";
import PriceDetail from "@/components/PriceDetail.vue";

const awSmallService = 14;
const awBrake = 9;
const awBigService = 23;
const awOilInGear = 13;
const aw2014Price = 95.6;
const aw2015Price = 143.4;

export const consts = _ => {
	return {
		awSmallService,
		awBrake,
		awBigService,
		awOilInGear,
		aw2014Price,
		aw2015Price
	}
};

export default {
	components: {PriceDetail},
	extends: BaseComponent,
	name: "PriceCards",
	data() {
		return {
			isVisibleDialogPriceDetail: false,
			selectedService: null,
			services: []
		}
	},
	methods: {
		handleSelectedService(service, showDetail) {
			this.selectedService = service;
			if (showDetail) {
				this.isVisibleDialogPriceDetail = true;
			}
		},
		createService(data) {
			return {
				...data,
				items: [
					{
						left: '<em class="fa-light fa-info-circle"></em> ' + this.t('smallService'),
						right1: this.getPrice(data.smallServicePrice, awSmallService, aw2014Price),
						right2: this.getPrice(data.smallServicePrice, awSmallService, aw2015Price),
						popover: {
							title: this.t('smallService'),
							content: this.t('smallServiceDescription')
						},
						rightClass: 'col-4 text-end',
						aw: awSmallService,
						price: data.smallServicePrice
					},
					{
						left: this.t('brakeFluids[0]'),
						right1: this.getPrice(1365, awBrake, aw2014Price),
						right2: this.getPrice(1365, awBrake, aw2015Price),
						leftClass: 'col-sm-8',
						rightClass: 'col-sm-4 text-sm-end',
						aw: awBrake,
						price: 1365
					},
					{
						left: this.t('brakeFluids[1]'),
						right1: this.getPrice(1690, awBrake, aw2014Price),
						right2: this.getPrice(1690, awBrake, aw2015Price),
						leftClass: 'col-sm-8',
						rightClass: 'col-sm-4 text-sm-end',
						aw: awBrake,
						price: 1690
					},
					{
						left: '<em class="fa-light fa-info-circle"></em> ' + this.t('bigService'),
						right1: this.getPrice(data.bigServicePrice, awBigService, aw2014Price),
						right2: this.getPrice(data.bigServicePrice, awBigService, aw2015Price),
						class: 'mt-2',
						popover: {
							title: this.t('bigService'),
							content: this.t('bigServiceDescription')
						},
						rightClass: 'col-4 text-end',
						aw: awBigService,
						price: data.bigServicePrice
					},
					{
						left: this.t('oilInGear'),
						right1: this.getPrice(data.oilInGearPrice, awOilInGear, aw2014Price),
						right2: this.getPrice(data.oilInGearPrice, awOilInGear, aw2015Price),
						leftClass: 'col-8',
						rightClass: 'col-4 text-end',
						aw: awOilInGear,
						price: data.oilInGearPrice
					},
					{
						left: this.t('brakeFluids[0]'),
						right1: this.getPrice(1365, awBrake, aw2014Price),
						right2: this.getPrice(1365, awBrake, aw2015Price),
						leftClass: 'col-sm-8',
						rightClass: 'col-sm-4 text-sm-end',
						aw: awBrake,
						price: 1365
					},
					{
						left: this.t('brakeFluids[1]'),
						right1: this.getPrice(1690, awBrake, aw2014Price),
						right2: this.getPrice(1690, awBrake, aw2015Price),
						leftClass: 'col-sm-8',
						rightClass: 'col-sm-4 text-sm-end',
						aw: awBrake,
						price: 1690
					}
				]
			}
		},
		getPrice(value, aw, awPrice) {
			let compValue = value + (aw * awPrice);

			return currencyFormatter.format(compValue, {
				code: 'CZK',
				precision: 0,
			});
		},
		initServices() {
			this.services = [];

			let svc1 = this.createService({
				guid: 'jepwu',
				preTitle: 'MY 1998 - 2005',
				title: '5-' + this.t('cylinder') + ' Euro3 - P2',
				images: [
					{original: require('@/assets/img/engines/5-cylinder-euro3.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-euro3.jpg')}
				],
				smallServicePrice: 4836,
				bigServicePrice: 6916,
				oilInGearPrice: 5070,
				disabledSwitch: true,
				mySwitchValue: false
			});
			this.services.push(svc1);

			let svc2 = this.createService({
				guid: 'npisa',
				preTitle: 'MY 2006 - 2011',
				title: '5-' + this.t('cylinder') + ' Euro4 - P1, P2, P3',
				images: [
					{original: require('@/assets/img/engines/5-cylinder-euro4-01.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-euro4-01.jpg')},
					{original: require('@/assets/img/engines/5-cylinder-euro4-02.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-euro4-02.jpg')}
				],
				smallServicePrice: 4875,
				bigServicePrice: 7436,
				oilInGearPrice: 5035,
				disabledSwitch: true,
				mySwitchValue: false
			});
			this.services.push(svc2);

			let svc3 = this.createService({
				guid: 'fmpsg',
				preTitle: 'MY 2011 - 2018',
				title: '5-' + this.t('cylinder') + ' Euro5 - P1, P2, P3',
				images: [
					{original: require('@/assets/img/engines/5-cylinder-euro5-01.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-euro5-01.jpg')},
					{original: require('@/assets/img/engines/5-cylinder-euro5-02.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-euro5-02.jpg')},
					{original: require('@/assets/img/engines/5-cylinder-euro5-03.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-euro5-03.jpg')},
					{original: require('@/assets/img/engines/5-cylinder-euro5-04.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-euro5-04.jpg')},
				],
				smallServicePrice: 4979,
				bigServicePrice: 7774,
				oilInGearPrice: 5252,
				disabledSwitch: false,
				mySwitchValue: false
			});
			this.services.push(svc3);

			let svc4 = this.createService({
				guid: 'updne',
				preTitle: 'MY 1998 - 2016',
				title: '5,6,8-' + this.t('cylinder') + ' ' + this.t('petrol') + ' - P1, P2, P3',
				images: [
					{original: require('@/assets/img/engines/5-cylinder-petrol-01.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-petrol-01.jpg')},
					{original: require('@/assets/img/engines/5-cylinder-petrol-02.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-petrol-02.jpg')},
					{original: require('@/assets/img/engines/5-cylinder-petrol-03.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-petrol-03.jpg')},
					{original: require('@/assets/img/engines/5-cylinder-petrol-04.jpg'), thumb: require('@/assets/img/engines/thumbs/5-cylinder-petrol-04.jpg')},
				],
				smallServicePrice: 4420,
				bigServicePrice: 8190,
				oilInGearPrice: 5070,
				disabledSwitch: false,
				mySwitchValue: false
			});
			this.services.push(svc4);

			let svc5 = this.createService({
				guid: 'vmxkb',
				preTitle: 'MY 2004 - 2014',
				title: '4-' + this.t('cylinder') + ' PSA - P1, P3',
				images: [
					{original: require('@/assets/img/engines/4-cylinder-psa-01.jpg'), thumb: require('@/assets/img/engines/thumbs/4-cylinder-psa-01.jpg')},
					{original: require('@/assets/img/engines/4-cylinder-psa-02.jpg'), thumb: require('@/assets/img/engines/thumbs/4-cylinder-psa-02.jpg')},
					{original: require('@/assets/img/engines/4-cylinder-psa-03.jpg'), thumb: require('@/assets/img/engines/thumbs/4-cylinder-psa-03.jpg')},
					{original: require('@/assets/img/engines/4-cylinder-psa-04.jpg'), thumb: require('@/assets/img/engines/thumbs/4-cylinder-psa-04.jpg')},
					{original: require('@/assets/img/engines/4-cylinder-psa-05.jpg'), thumb: require('@/assets/img/engines/thumbs/4-cylinder-psa-05.jpg')},
				],
				smallServicePrice: 4082,
				bigServicePrice: 6136,
				oilInGearPrice: 6500,
				disabledSwitch: true,
				mySwitchValue: false
			});
			this.services.push(svc5);

			let svc6 = this.createService({
				guid: 'iaivh',
				preTitle: 'MY 2014 - 2023',
				title: 'VEA - ' + this.t('diesel').toLowerCase() + ' - P3, SPA, CMA',
				images: [
					{original: require('@/assets/img/engines/vea-diesel-01.jpg'), thumb: require('@/assets/img/engines/thumbs/vea-diesel-01.jpg')},
					{original: require('@/assets/img/engines/vea-diesel-02.jpg'), thumb: require('@/assets/img/engines/thumbs/vea-diesel-02.jpg')},
				],
				smallServicePrice: 5181,
				bigServicePrice: 9425,
				oilInGearPrice: 5460,
				disabledSwitch: false,
				mySwitchValue: false
			});
			this.services.push(svc6);

			let svc7 = this.createService({
				guid: 'jbjwi',
				preTitle: 'MY 2014 - 2023',
				title: 'VEA - ' + this.t('petrol').toLowerCase() + ' - P3, SPA, CMA',
				images: [
					{original: require('@/assets/img/engines/vea-petrol-01.jpg'), thumb: require('@/assets/img/engines/thumbs/vea-petrol-01.jpg')},
					{original: require('@/assets/img/engines/vea-petrol-02.jpg'), thumb: require('@/assets/img/engines/thumbs/vea-petrol-02.jpg')},
				],
				smallServicePrice: 5181,
				bigServicePrice: 8580,
				oilInGearPrice: 5460,
				disabledSwitch: false,
				mySwitchValue: false
			});
			this.services.push(svc7);
		}
	},
	mounted() {
		this.initServices();

		this.$nextTick(() => {
			new SimpleLightbox('.engine-images a', {
				fadeSpeed: 200
			});
		});

		mittInstance().on('languageChanged', () => this.initServices());
	},
}
</script>

<style>
.table-home tr:hover {
	background: #e5e9f2;
}

.el-popper.is-light {
	color: #ffffff;
	background: #303133;
	border: 1px solid #303133;
}

.el-popover__title {
	color: #E5EAF3;
}

.el-popper.is-light .el-popper__arrow::before {
	background: #303133;
}

.service-dialog .el-dialog__body {
	padding-top: 10px;
	padding-bottom: 10px;
}

.service-dialog {
	max-width: 800px;
}
</style>

<style scoped>
.engine-img {
	height: 230px;
}

.carousel-control-prev-icon, .carousel-control-next-icon, .carousel-control-prev, .carousel-control-next {
	opacity: 1;
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.service-title {
	display: flex;
	justify-content: space-between;
}
</style>
