<template>
	<footer class="footer mt-5">
		<div class="container pt-4">
			<div class=" row">
				<div class="col-md-3 col-sm-6 col-6 mb-4 ms-auto">
					<div>
            <router-link to="/">
							<img :src="require('@/assets/img/logo-172x31.png')" class="mb-3 main-logo" alt="main_logo">
            </router-link>
						<h6 class="font-weight-bolder mb-4">{{ $t('companyName') }}</h6>
					</div>

					<div>
						<ul class="d-flex flex-row ms-n3 nav">
							<li class="nav-item">
								<a class="nav-link pe-1" :href="$t('facebookLink')" target="_blank">
									<em class="fab fa-facebook text-lg opacity-8"></em>
								</a>
							</li>

							<li class="nav-item">
								<a class="nav-link pe-1" :href="$t('twitterLink')" target="_blank">
									<em class="fab fa-twitter text-lg opacity-8"></em>
								</a>
							</li>

							<li class="nav-item">
								<a class="nav-link pe-1" :href="$t('youtubeLink')"
									target="_blank">
									<em class="fab fa-youtube text-lg opacity-8"></em>
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div class="col-md-3 col-sm-6 col-6 mb-4">
					<div>
						<h6 class="text-sm">{{ $t('about') }}</h6>
						<ul class="flex-column ms-n3 nav">
							<li class="nav-item">
								<span class="nav-link">{{ $t('companyStreet') }}</span>
							</li>
							<li class="nav-item">
								<span class="nav-link">{{ $t('companyCity') }}</span>
							</li>
							<li class="nav-item">
								<span class="nav-link">{{ $t('companyPostalCode') }}</span>
							</li>
							<li class="nav-item">
								<span class="nav-link">{{ $t('companyVat') }}</span>
							</li>
							<li class="nav-item">
								<span class="nav-link">{{ $t('companyTax') }}</span>
							</li>
							<li class="nav-item">
                <a class="nav-link" :href="`mailto:` + $t('companyEmail')">
                  {{ $t('companyEmail') }}
                </a>
							</li>
							<li class="nav-item">
                <a class="nav-link" :href="`tel:` + $t('companyMobile')">
                  {{ $t('companyMobile') }}
                </a>
							</li>
						</ul>
					</div>
				</div>

				<div class="col-md-3 col-sm-6 col-6 mb-4">
					<div>
						<h6 class="text-sm">{{ $t('ourServices') }}</h6>
						<ul class="flex-column ms-n3 nav">
							<li class="nav-item">
                <router-link tag="a" :to="{ name: 'ElectricalDiagnostics' }" class="nav-link">
                  {{ $t('diagnosticsElectricalInstallation') }}
                </router-link>
							</li>
							<li class="nav-item">
                <router-link tag="a" :to="{ name: 'DiagnosticMechanicalFaults' }" class="nav-link">
                  {{ $t('diagnosticMechanicalFaults') }}
                </router-link>
							</li>
              <li class="nav-item">
                <router-link tag="a" :to="{ name: 'AutomaticGearboxDecarbonisation' }" class="nav-link">
                  {{ $t('automaticGearboxDecarbonisation') }}
                </router-link>
							</li>
              <li class="nav-item">
                <router-link tag="a" :to="{ name: 'OptimizingEnginePerformance' }" class="nav-link">
                  {{ $t('optimizingEnginePerformance') }}
                </router-link>
							</li>
              <li class="nav-item">
                <router-link tag="a" :to="{ name: 'DiagnosticTrainingCourses' }" class="nav-link">
                  {{ $t('diagnosticTrainingCourses') }}
                </router-link>
							</li>
              <li class="nav-item">
                <router-link tag="a" :to="{ name: 'ClassroomCarRental' }" class="nav-link">
                  {{ $t('classroomCarRental') }}
                </router-link>
							</li>
						</ul>
					</div>
				</div>

				<div class="col-md-3 col-sm-6 col-6 mb-4">
					<div>
						<h6 class="text-sm">{{ $t('openingHours') }}</h6>
						<ul class="ms-n3 list list-unstyled">
							<li class="nav-item">
                <span class="nav-link">
                  <span class="day">{{ $t('monday') }}:</span> 9:00 - 16:00
                </span>
							</li>
							<li class="nav-item">
                <span class="nav-link">
                  <span class="day">{{ $t('tuesday') }}:</span> 9:00 - 16:00
                </span>
							</li>
							<li class="nav-item">
                <span class="nav-link">
                  <span class="day">{{ $t('wednesday') }}:</span> 9:00 - 16:00
                </span>
							</li>
							<li class="nav-item">
                <span class="nav-link">
                  <span class="day">{{ $t('thursday') }}:</span> 9:00 - 16:00
                </span>
							</li>
							<li class="nav-item">
                <span class="nav-link">
                  <span class="day">{{ $t('friday') }}:</span> 9:00 - 16:00
                </span>
							</li>
							<li class="nav-item">
                <span class="nav-link">
                  <span class="day">{{ $t('saturday') }}:</span> <span class="text-uppercase">{{ $t('closed') }}</span>
                </span>
							</li>
              <li class="nav-item">
                <span class="nav-link">
                  <span class="day">{{ $t('sunday') }}:</span> <span class="text-uppercase">{{ $t('closed') }}</span>
                </span>
							</li>
						</ul>
					</div>
				</div>

				<div class="col-12">
					<div class="text-center">
						<p class="text-dark my-4 text-sm font-weight-normal">
              {{ $t('copyright') }}
              <br><span class="fw-bold">{{ $t('allPricesWithVat') }}</span>
              <br> Material Kit by <a href="https://www.creative-tim.com" target="_blank">Creative Tim</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<style lang="scss" scoped>
.main-logo {
  max-width: 155px;
}

.list .day {
  float: left;
  min-width: 40%;
}
</style>
