<script>
import {i18n} from "@/main";

export default {
  name: "BaseArticle",
  methods: {
    t(key) {
      return i18n.global.t(key);
    }
  }
}
</script>

<style lang="scss">
.base-article {
  & section {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
    margin-top: -1rem !important;
  }

  & section .wrapper {
    text-align: left !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  & section .wrapper.title {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  & .wrapper p {
    font-size: 1.25rem !important;
    margin-bottom: 1rem !important;
  }
}
</style>
