import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import { i18n } from '@/main'
import { getLocale } from  '@/lang/index';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact')
  },
  {
    path: '/vin/:vin',
    name: 'Vin',
    component: () => import(/* webpackChunkName: "vin" */ '@/views/Vin')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/NotFound')
  },
  {
    path: '/services/electrical-diagnostics',
    name: 'ElectricalDiagnostics',
    component: () => import(/* webpackChunkName: "electricalDiagnostics" */ '@/views/ElectricalDiagnostics')
  },
  {
    path: '/services/diagnostic-mechanical-faults',
    name: 'DiagnosticMechanicalFaults',
    component: () => import(/* webpackChunkName: "diagnosticMechanicalFaults" */ '@/views/DiagnosticMechanicalFaults')
  },
  {
    path: '/services/automatic-gearbox-decarbonisation',
    name: 'AutomaticGearboxDecarbonisation',
    component: () => import(/* webpackChunkName: "automaticGearboxDecarbonisation" */ '@/views/AutomaticGearboxDecarbonisation')
  },
  {
    path: '/services/optimizing-engine-performance',
    name: 'OptimizingEnginePerformance',
    component: () => import(/* webpackChunkName: "optimizingEnginePerformance" */ '@/views/OptimizingEnginePerformance')
  },
  {
    path: '/services/diagnostic-training-courses',
    name: 'DiagnosticTrainingCourses',
    component: () => import(/* webpackChunkName: "diagnosticTrainingCourses" */ '@/views/DiagnosticTrainingCourses')
  },
  {
    path: '/services/classroom-car-rental',
    name: 'ClassroomCarRental',
    component: () => import(/* webpackChunkName: "classroomCarRental" */ '@/views/ClassroomCarRental')
  },
  {
    path: '/i-want-service',
    name: 'IWantService',
    component: () => import(/* webpackChunkName: "iWantService" */ '@/views/IWantService')
  },
  {
    path: '/voucher/:code',
    name: 'Voucher',
    component: () => import(/* webpackChunkName: "Voucher" */ '@/views/Voucher')
  }
];

const instance = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.name === from.name) { //simplelightbox hack
      return {};
    }
    return { top: 0 }
  },
});

const links = {
  'sendMailService':  process.env.VUE_APP_API_URL + 'v1/web/sendMailService'
};

instance.beforeEach((to, from, next) => {
  i18n.global.locale = getLocale();
  return next();
});

export default { instance, links }
