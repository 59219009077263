<template>

</template>

<script>
import helpers from "@/helpers";
import axios from "axios";
import {i18n} from "@/main";

export default {
  name: 'BaseComponent',
  data() {
      return {
        showLoading: false,
        isDev: process.env.NODE_ENV === 'development'
      }
  },
  methods: {
    t(key) {
      return i18n.global.t(key);
    },
    fetchGet(link, showLoading = true, fullResponse = false) {
      return new Promise(async(resolve, reject) => {
        if (showLoading === true) {
          this.showLoading = true;
        }

        try {
          let response = await axios.get(link, helpers.axios.config());

          if (showLoading === true) {
            this.showLoading = false;
          }

          if (response.status === 200) {
            resolve(fullResponse ? response : response.data);
          }
        }
        catch (e) {
          console.log(e);
          reject(e);
        }
      });
    },
    fetchPost(link, data, showLoading = true, fullResponse = false) {
      return new Promise(async (resolve, reject) => {

        if (showLoading === true) {
          this.showLoading = true;
        }

        try {
          let response = await axios.post(link, data, helpers.axios.config());
          if (showLoading === true) {
            this.showLoading = false;
          }

          if (response.status === 200) {
            if (fullResponse) {
              resolve(response);
            } else {
              if (response.data.error === true) {
                this.$alert(response.data.result);
              } else {
                resolve(response.data.result);
              }
            }
          }
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    }
  }
}
</script>

<style scoped>

</style>