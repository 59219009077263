<template>
	<div class="service-title my-2">
		<span class="text-gradient text-warning text-uppercase text-xs font-weight-bold my-2">{{ service.preTitle }}</span>
		<el-switch
				v-show="service.disabledSwitch === false"
				v-model="service.mySwitchValue"
				:disabled="service.disabledSwitch"
				:active-text="t('from') + ' MY2015'"
				:inactive-text="t('to') + ' MY2014'"
				active-color="#a2d986"
				inactive-color="#409eff">
		</el-switch>
	</div>

	<div class="table-responsive">
		<table class="table table-hover">
			<thead>
			<tr>
				<th class="p-0" scope="col">{{ $t('item') }}</th>
				<th class="p-0" scope="col">{{ $t('material') }}</th>
				<th class="p-0" scope="col"><abbr :title="'1 AW = 6 ' + t('abbrMinutes')">AW</abbr></th>
				<th class="p-0" scope="col">{{ $t('work') }}</th>
				<th class="p-0" scope="col">{{ $t('total') }}</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="item in service.items">
				<td>
					<span v-if="item.popover === undefined" v-html="item.left"/>
					<el-popover
							v-else
							:title="item.popover.title"
							:width="200"
							placement="top-start"
							trigger="hover">
						<template #reference>
							<span class="text-bold" v-html="item.left"/>
						</template>
						<template #default>
							<span v-html="item.popover.content"></span>
						</template>
					</el-popover>
				</td>
				<td>{{ getPrice(item.price) }}</td>
				<td>{{ item.aw }}</td>
				<td>{{ getPrice(0, item.aw, awPrice) }}</td>
				<th scope="row">{{ getPrice(item.price, item.aw, awPrice) }}</th>
			</tr>
			</tbody>
		</table>
	</div>
	<figure class="mt-4">
		<figcaption class="blockquote-footer">
			{{ $t('myDesc') }}
		</figcaption>
		<figcaption class="blockquote-footer">
			{{ $t('pricePerHour') }}: {{ getPrice(awPrice * 10) }}, 1 AW = 6 {{ $t('abbrMinutes') }} | {{ getPrice(awPrice, 0, 0, 1) }}
			<br>
			{{ $t('additionalPriceForServiceWork') }}
		</figcaption>
	</figure>
</template>

<script>
import BaseComponent from "@/components/BaseComponent";
import currencyFormatter from "currency-formatter";
import {consts} from "@/components/PriceCards.vue";

const defaultProps = {
	children: 'children',
	label: 'label',
};

export default {
	extends: BaseComponent,
	name: "PriceDetail",
	props: {
		service: {
			type: Object,
			required: true
		},
	},
	data() {
		return {}
	},
	methods: {
		getPrice(value, aw = 0, awPrice = 0, precision = 0) {
			let compValue = value + (aw * awPrice);

			return currencyFormatter.format(compValue, {
				code: 'CZK',
				precision: precision,
			});
		},
	},
	computed: {
		awPrice() {
			return this.service.mySwitchValue ? consts().aw2015Price : consts().aw2014Price;
		}
	},
	mounted() {

	}
}
</script>

<style scoped>
.service-title {
	display: flex;
	justify-content: space-between;
}
</style>