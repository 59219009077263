import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
// import '@fortawesome/fontawesome-pro/scss/solid.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/brands.scss';
import '@/assets/scss/cardiag.scss';
import '@/assets/scss/material-kit.scss';

// import '@/assets/js/core/popper.min.js';
import '@/assets/js/core/bootstrap.min';
import '@/assets/js/plugins/perfect-scrollbar.min.js';
// import '@/assets/js/plugins/parallax.min.js';
import '@/assets/js/material-kit.js';

//Element-plus
import ElementPlus from 'element-plus';
import elementCS from 'element-plus/es/locale/lang/cs'
import 'element-plus/dist/index.css';

//Lightbox
import 'simplelightbox/dist/simple-lightbox.css';

import {createApp} from 'vue';
import {createI18n} from 'vue-i18n'
import App from './App.vue';
import router from './router';
import messages from './lang';
import flagIcon from 'vue-flag-icon'
import VueGtag from "vue-gtag";
import mitt from "mitt";
let mittInst = mitt();

export function mittInstance() {
	return mittInst;
}

export const i18n = createI18n({
	locale: 'en',
	fallbackLocale: {
		'sk': ['cs'],
		'default': ['cs']
	},
	messages
});

createApp(App)
	.use(flagIcon)
	.use(i18n)
	.use(router.instance)
	.use(ElementPlus, {locale: elementCS})
	.use(VueGtag, {
		includes: [
			{ id: 'AW-971213083' }
		],
		config: { id: 'G-26F6T5MYSY' }
	}, router.instance)
	.mount('#app');
